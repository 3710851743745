import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://789bk.org/loginv1";
export const REGISTER_URL = "https://789bk.org/regv1";

export const FLOATING_BANNER_URL = "https://789bk.org/linev1";

export * from "./codes";
export * from "./context";
