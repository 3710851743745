import React from "react";
import {InstallBanner, LandingLayout } from "src/main/components";
import LandingPage from "./LandingPage";

interface MainProps extends React.PropsWithChildren {

}
const Main: React.FC<MainProps> = (props) => {
  return (
    <LandingLayout.Content>
       <InstallBanner />
      {/* <LandingLayout.TopBar /> */}
      <LandingPage />
    </LandingLayout.Content>
  );
};


export default Main;
