import moment, { Moment } from "moment";
import preval from "preval.macro";
import { Variables } from "./type";

export const reduceVariables = (variables: Variables, accessors: string[] = []): [string, string][] => {
  const pairs: [string, string][] = [];
  for (const key in variables) {
    const a = accessors.concat(key);
    const v = variables[key];
    if (typeof v === "number" || typeof v === "string") {
      pairs.push([a.join("."), v]);
    } else if (v instanceof moment) {
      pairs.push([a.join("."), moment(v).format("YYYY-MM-DD HH:mm:ss")]);
    } else {
      const p = reduceVariables(v as Variables, a);
      pairs.push(...p);
    }
  }
  return pairs;
};

export const wait = (duration: number) => {
  return new Promise((res) => setTimeout(res, duration));
};

export const parseMoment = <T = Moment>(
  input: undefined | null | number | string | Date | Moment,
  defaultValue?: T,
): Moment | T | undefined => {
  if (input === null || input === undefined) return defaultValue;
  if (moment.isMoment(input)) return input as Moment;
  if (typeof input === "number") return moment.unix(input);
  if (typeof input === "string" && input.match(/^\d{0,10}$/)) return moment.unix(parseInt(input));
  try {
    return moment(input);
  } catch (error) {
    console.warn("parse moment failed");
    console.warn(error);
    return undefined;
  }
};

export interface AppEnv {
  build: string;
}
// evaluates at yarn build
const BUILD_TIMESTAMP: string = preval`module.exports = require('dayjs')().format('YYYYMMDD/HHmmss')`;
const appEnv: AppEnv = {
  build: BUILD_TIMESTAMP,
};
export const getAppEnv = () => {
  return appEnv;
};
