"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  addParams: () => addParams,
  getRedirectUrl: () => getRedirectUrl,
  runner: () => runner,
  serviceWorkerRegistration: () => shared_service_worker_registration_exports
});
module.exports = __toCommonJS(src_exports);

// src/shared-service-worker.ts
function runner(self) {
  console.log("shared runner is running");
  const CACHE_VERSION_KEY = process.env.REACT_APP_CACHE_VERSION_KEY ?? "CACHE_VERSION_KEY-";
  const PUSH_NOTIFICATION_ENDPOINT2 = process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT;
  const thisUrl = self.location.origin;
  self.addEventListener("activate", async () => {
    console.log("activate");
    const cacheNames = await caches.keys();
    try {
      const response = await fetch(process.env.PUBLIC_URL + "/version.json");
      const { version } = await response.json();
      if (!!version) {
        const cacheVersion = CACHE_VERSION_KEY + version;
        let oldVersion = cacheNames.find(
          (cacheName) => cacheName.includes(CACHE_VERSION_KEY) && cacheName !== cacheVersion
        );
        if (!!oldVersion) {
          let reload = false;
          await Promise.all(
            cacheNames.map(async (cacheName) => {
              if (cacheVersion !== cacheName) {
                reload = true;
                await caches.delete(cacheName);
              }
            })
          );
          if (reload) {
            window.location.reload();
          }
        }
      }
    } catch (error) {
    }
  });
  self.addEventListener("install", (event) => {
    console.log("install");
    self.skipWaiting();
    event.waitUntil(
      (async () => {
        const response = await fetch(process.env.PUBLIC_URL + "/version.json");
        const { version } = await response.json();
        const cacheVersion = CACHE_VERSION_KEY + version;
        (await caches.open(cacheVersion)).addAll(["/"]);
      })()
    );
  });
  self.addEventListener("message", (event) => {
    console.log("message");
    if (event.data && event.data.type === "SKIP_WAITING") {
      self.skipWaiting();
    }
  });
  if (!!PUSH_NOTIFICATION_ENDPOINT2) {
    self.addEventListener("push", async function(event) {
      console.log("push");
      if (!event.data)
        return;
      const message = await event.data.json();
      let { title, description, icon, urlAction } = message;
      const defaultIcon = `${process.env.PUBLIC_URL}/favicon-256x256.png`;
      event.waitUntil(
        this.registration.showNotification(title, {
          body: description,
          icon: icon ?? defaultIcon,
          data: {
            urlAction
          }
        })
      );
    });
    self.addEventListener("notificationclick", function(event) {
      console.log("notificationclick");
      const urlAction = event?.notification?.data?.urlAction;
      let url = "";
      try {
        url = new URL(urlAction).toString();
      } catch (error) {
        console.log(error.message);
      }
      event.notification.close();
      if (url) {
        if (self.clients.openWindow) {
          event.waitUntil(self.clients.openWindow(url));
        }
      } else {
        event.waitUntil(
          self.clients.matchAll({ type: "window" }).then((windowClients) => {
            for (var i = 0; i < windowClients.length; i++) {
              var client = windowClients[i];
              if (client && client.url === thisUrl && "focus" in client) {
                return client.focus();
              }
            }
            if (self.clients.openWindow) {
              return self.clients.openWindow(thisUrl);
            }
          })
        );
      }
    });
  }
}

// src/shared-service-worker-registration.ts
var shared_service_worker_registration_exports = {};
__export(shared_service_worker_registration_exports, {
  register: () => register,
  unregister: () => unregister
});
var PUSH_NOTIFICATION_ENDPOINT = process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT;
var TEST_NOTIFICATION_PARAM = process.env.REACT_APP_TEST_NOTIFICATION_PARAM;
var PUSH_NOTIFICATION_STORAGE_KEY = process.env.REACT_APP_PUSH_NOTIFICATION_STORAGE_KEY ?? "push-notification-register-status";
function register(config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL ?? "", window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const isLocalhost = Boolean(
        window.location.hostname === "localhost" || // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" || // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
      );
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA"
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}
async function subscribe(serviceWorkerReg) {
  if (!PUSH_NOTIFICATION_ENDPOINT)
    return;
  let version = "";
  try {
    const response = await fetch(process.env.PUBLIC_URL + "/version.json");
    version = ((await response.json()).version || "").toString();
    if (!version)
      throw new Error("Invalid version");
    const registeredAtVersion = sessionStorage.getItem(PUSH_NOTIFICATION_STORAGE_KEY);
    if (version && registeredAtVersion === version)
      return;
  } catch (error) {
    console.log(error);
  }
  const notificationInfoResponse = await fetch(PUSH_NOTIFICATION_ENDPOINT + "/notification/info", { method: "GET" });
  const {
    result: { subscribeEndpoint, vapidKey, skip }
  } = await notificationInfoResponse.json();
  if (skip)
    return;
  const params = new URLSearchParams(window.location.search);
  const notifClient = params.get(TEST_NOTIFICATION_PARAM ?? "");
  if (!!subscribeEndpoint) {
    let subscription = await serviceWorkerReg.pushManager.getSubscription();
    if (!subscription && !!vapidKey) {
      let unsub = false;
      try {
        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidKey
        });
      } catch (error) {
        if ("message" in error) {
          if (error.message.startsWith("Registration failed - A subscription with a different applicationServerKey")) {
            unsub = true;
          }
        }
      }
      if (unsub && !!subscription) {
        await subscription.unsubscribe();
        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidKey
        });
      }
    }
    if (!!subscription) {
      const domain = window.location.hostname ?? "";
      await fetch(PUSH_NOTIFICATION_ENDPOINT + subscribeEndpoint, {
        method: "POST",
        body: JSON.stringify({
          subscription,
          client: notifClient ?? domain
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      sessionStorage.setItem(PUSH_NOTIFICATION_STORAGE_KEY, version);
    }
  }
}
function registerValidSW(swUrl, config) {
  navigator.serviceWorker.register(swUrl).then(async (registration) => {
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller) {
            console.log(
              "New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA."
            );
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            } else {
              registration.update().then(() => {
                window.location.reload();
              });
            }
          } else {
            console.log("Content is cached for offline use.");
            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
      };
    };
    return navigator.serviceWorker.ready;
  }).then(async (registration) => {
    await subscribe(registration);
  }).catch((error) => {
    console.error("Error during service worker registration:", error);
  });
}
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
    headers: { "Service-Worker": "script" }
  }).then((response) => {
    const contentType = response.headers.get("content-type");
    if (response.status === 404 || contentType != null && contentType.indexOf("javascript") === -1) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      registerValidSW(swUrl, config);
    }
  }).catch(() => {
    console.log("No internet connection found. App is running in offline mode.");
  });
}
function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    }).catch((error) => {
      console.error(error.message);
    });
  }
}

// src/url.ts
var import_query_string = __toESM(require("query-string"));
var addParams = (url, params) => {
  const [baseUrl, query] = url.split("?");
  const parsedParams = import_query_string.default.parse(query ?? "");
  const newParams = { ...parsedParams, ...params };
  return `${baseUrl}?${import_query_string.default.stringify(newParams)}`;
};
var getRedirectUrl = (ENTER_SITE_URL, fbcid, params, RefCodeMap, FallbackRefCode) => {
  const hostname = window.location.hostname;
  const [refCode, pixelId] = RefCodeMap[hostname] ?? FallbackRefCode ?? [void 0, void 0];
  const code = params.has("code") ? params.get("code") ?? refCode : refCode;
  const pixel = params.has("pixel") ? params.get("pixel") ?? pixelId : pixelId;
  const urlParams = {
    id: code,
    fb_dynamic_pixel: typeof pixel === "string" ? pixel : void 0,
    fbcid: fbcid ? fbcid : void 0
  };
  return addParams(ENTER_SITE_URL, urlParams);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  addParams,
  getRedirectUrl,
  runner,
  serviceWorkerRegistration
});
