import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useFbcid = () => {
  const [params] = useSearchParams();

  const fbcid = useMemo(() => {
    const fbcidParam = params.get("fbcid");

    try {
      const fbcidLocal = localStorage.getItem("fbcid");

      if (fbcidParam && fbcidLocal !== fbcidParam) {
        localStorage.setItem("fbcid", fbcidParam);
      }

      return fbcidParam ?? fbcidLocal ?? null;
    } catch (error) {
      return fbcidParam ?? null;
    }
  }, [params]);

  return { fbcid };
};

export default useFbcid;
