"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const VisuallyHidden = ({ as, children, ...props }) => {
    const Component = as || "span";
    const style = {
        position: "absolute",
        width: "1px",
        height: "1px",
        margin: "-1px",
        padding: "0",
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        border: "0",
    };
    return ((0, jsx_runtime_1.jsx)(Component, { style: style, ...props, children: children }));
};
exports.default = VisuallyHidden;
