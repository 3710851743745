import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import {
  AppDownload,
  Bank,
  Car,
  Coin,
  CrystalGems,
  Flair,
  FlickrLight,
  Lady,
  LoginButton,
  Logo,
  Pig,
  Pikaso,
  Rabbit,
  Rainbow,
  RegisterButton,
  Scatter,
  Shape,
  Star,
  Striker,
  Wild,
  Winner,
} from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  const getDomainTag = useCallback(() => {
    return (
      <a
        href="/#"
        onClick={(e) => e.preventDefault()}
      >
        789BK
      </a>
    );
  }, []);

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">789BK</VisuallyHidden>
      <Typography
        sx={{
          fontSize: "33px",
          fontWeight: 700,
          background: `linear-gradient(179deg, #C2F6FF -45.49%, #C2F6FF 56.49%, #459CFD 62.16%, #60D2FF 86.24%, #5093FD 96.15%)`,
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
        }}
      >
        แพลตฟอร์มชั้นนำ
      </Typography>
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 600,
          marginTop: "-8px",
        }}
      >
        มาตรฐาน U.S.A
      </Typography>

      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={-1}
      >
        <img
          alt="logo"
          src={Logo}
          height={217}
          width={217}
          style={{ marginTop: -55, marginBottom: -50 }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          สล็อตออนไลน์อันดับ 1 ในไทย{" "}
        </Typography>
        <Box sx={{ zIndex: 1000 }}>
          <a
            href={loginUrl}
            onClick={handleClick("login")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="login button"
              src={LoginButton}
              width={155}
              height={60}
              style={{ marginRight: -12 }}
            />
          </a>
          <a
            href={registerUrl}
            onClick={handleClick("register")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="register button"
              src={RegisterButton}
              width={154}
              height={60}
              style={{ marginLeft: -12 }}
            />
          </a>
        </Box>
      </Stack>

      <Box sx={styles.images}>
        <img
          alt="flair"
          src={Flair}
          style={{
            // animation: "herocoins 1.2s ease-out 0.6s infinite, fadeCoins 0.6s linear",
            left: 49,
            bottom: 0,
          }}
        />
        <img
          alt="flickr light"
          src={FlickrLight}
          style={{
            animation: "flickr 2s ease-out 0s infinite",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 118,
          }}
        />
        <img
          alt="striker"
          src={Striker}
          style={{
            animation: "striker 2s ease-out 0s infinite",
            left: 72,
            bottom: 44,
          }}
        />
        <img
          alt="winner"
          src={Winner}
          style={{
            animation: "winner 2s ease-out 0s infinite",
            left: 97,
            bottom: 54,
          }}
        />
        <img
          alt="rabbit"
          src={Rabbit}
          style={{
            animation: "rabbit 2s ease-out 0s infinite",
            left: 250,
            bottom: 208,
          }}
        />
        <img
          alt="lady"
          src={Lady}
          style={{
            animation: "lady 2s ease-out 0s infinite",
            left: 154,
            bottom: 78,
          }}
        />
        <img
          alt="shape"
          src={Shape}
          style={{
            left: 11,
            bottom: 8,
          }}
        />
        <img
          alt="rainbow"
          src={Rainbow}
          style={{
            animation: "rainbow 2s ease-out 0s infinite",
            left: 0,
            bottom: 0,
          }}
        />
        <img
          alt="pig"
          src={Pig}
          style={{
            left: 70,
            bottom: 100,
          }}
        />
        <img
          alt="car"
          src={Car}
          style={{
            left: 28,
            bottom: 79,
          }}
        />
        <img
          alt="wild"
          src={Wild}
          style={{
            animation: "wild 2s ease-out 0s infinite",
            left: 25,
            bottom: 291,
          }}
        />
        <img
          alt="scatter"
          src={Scatter}
          style={{
            animation: "scatter 2s ease-out 0s infinite",
            left: 277,
            bottom: 152,
          }}
        />
        <img
          alt="coin"
          src={Coin}
          style={{
            animation: "coin 2s ease-out 0s infinite",
            left: 11,
            bottom: 73,
          }}
        />
        <img
          alt="star"
          src={Star}
          style={{
            animation: "star 2s ease-out 0s infinite",
            left: 182,
            bottom: 150,
          }}
        />

        {/* <img
          alt="hero coins"
          src={HeroCoins}
          style={{
            animation: "herocoins 1.2s ease-out 0.6s infinite, fadeCoins 0.6s linear",
            left: 10,
            top: 225,
          }}
        /> */}
      </Box>

      <Stack
        maxWidth={360}
        spacing={2}
        p={2}
        mt={-2}
        sx={{
          zIndex: 100,
          textAlign: "center",

          "& a": {
            color: "#00FFE4",
            textDecoration: "none",
          },
          "& p": {
            fontSize: "12px",
            fontWeight: 400,
          },
        }}
      >
        <Typography>ยินดีต้อนรับสู่ {getDomainTag()} - แหล่งคาสิโนออนไลน์ที่น่าเชื่อถือและทันสมัย</Typography>
        <Typography>
          ท่านสมาชิกจะพบกับประสบการณ์การเล่นคาสิโนออนไลน์ที่ไม่เหมือนใครที่ {getDomainTag()}
          ที่นี่เรามุ่งมั่นที่จะให้คุณได้รับประสบการณ์การเล่นที่มั่นคงปลอดภัย และการให้บริการที่รวดเร็วทันใจ
          ใส่ใจทุกยูสเซอร์อย่างมีคุณภาพ เพื่อความสนุกสนานและความสุขของท่าน
        </Typography>
        <Typography>
          {getDomainTag()} มีทุกสิ่งที่คุณต้องการในการเล่นคาสิโนออนไลน์ ตั้งแต่เกมคาสิโนที่ได้รับความนิยม เช่น บาคาร่า,
          รูเล็ต, และแบล็คแจ็ค ไปจนถึงเกมสล็อตค่ายดังต่างๆ ที่มาพร้อมกับ รางวัลแจ็คพอตใหญ่ที่มีมูลค่ามหาศาล
        </Typography>
        <Typography>
          {getDomainTag()} เราเชื่อว่าความน่าเชื่อถือและความปลอดภัยเป็นสิ่งที่สำคัญที่สุด เราใช้เทคโนโลยี
          ที่ทันสมัยเพื่อรักษาความปลอดภัยของข้อมูลของท่าน และมีการสนับสนุนทีมที่พร้อมให้บริการตลอด 24 ชั่วโมง
          เพื่อช่วยเหลือและแก้ไขปัญหาของท่านได้อย่างฉับไว
        </Typography>
        <Typography>
          ร่วมสนุกและร่วมเป็นส่วนหนึ่งกับเรา {getDomainTag()} เรามีขั้นตอนการสมัครสมาชิกที่ง่ายและปลอดภัย
          และท่านยังสามารถใช้งานได้ทุกแพลตฟอร์ม! ในขณะเดียวกัน
          สมาชิกใหม่สามารถรับโบนัสต้อนรับที่น่าตื่นตาตื่นใจเมื่อลงทะเบียนเข้าร่วมกับ {getDomainTag()}
        </Typography>
        <Typography>
          {getDomainTag()} มุ่งมั่นที่จะให้คุณได้รับประสบการณ์การเล่นคาสิโนออนไลน์ที่ท้าทายและน่าจดจำที่สุด
          มาร่วมสนุกกับเราและเป็นส่วนหนึ่งของความสำเร็จกับ {getDomainTag()} ได้แล้ววันนี้!
        </Typography>
      </Stack>

      <Box sx={styles.footer}>
        <img
          alt="crystal gems"
          src={CrystalGems}
          width={346}
          height={186}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            margin: "0 auto",
            zIndex: 5,
          }}
        />
        <img
          alt="pikaso"
          src={Pikaso}
          width={360}
          height={180}
          style={{
            position: "absolute",
            top: 56,
            left: -18,
            right: 0,
            zIndex: 4,
          }}
        />
        <img
          alt="logo"
          src={Logo}
          width="245"
          height="245"
          style={{ marginTop: -38, marginBottom: -38 }}
        />
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
        >
          <img
            alt="logo"
            src={Bank}
            width={153}
            height={40}
          />
          <img
            alt="app"
            src={AppDownload}
            width={155}
            height={40}
          />
        </Stack>
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "20px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "426px",
    marginTop: "-40px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    maxWidth: "360px",
    height: "200px",
    marginBottom: "100px",
    textAlign: "center",
    position: "relative",
    img: {
      position: "relative",
      zIndex: 10,
    },
  },
});

export default LandingPage;
